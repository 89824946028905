<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="`/private/private-lessons/${teachingLog.class_id}/detail`"
          >
            {{ classInfo.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>Lesson Log Details</el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h3>{{ classInfo.title }} - Lesson Log</h3>
    <hr class="separate-line" />
    <el-card
      class="box-card"
      v-for="lessonLog in lessonLogs"
      :key="lessonLog.id"
    >
      <div slot="header" class="clearfix">
        <span>Details</span>
        <router-link
          :to="`/private/private-lessons/${teachingLog.class_id}/editLog/${lessonLog.id}`"
        >
          <el-button style="float: right; padding: 3px 0" type="text"
          >Edit</el-button
          >
      </router-link>
      </div>
      <el-form ref="form" label-width="180px">
        <el-form-item label="Student">
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: lessonLog.student_user.id }
            }"
          >
            {{
              `${user.displayName(
                lessonLog.student_user.first_name,
                lessonLog.student_user.last_name,
                lessonLog.student_user.chinese_name
              )}`
            }}
          </router-link>
        </el-form-item>
        <el-form-item label="Teacher">
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: teachingLog.teacher_user.id }
            }"
          >
            {{
              `${user.displayName(
                teachingLog.teacher_user.first_name,
                teachingLog.teacher_user.last_name,
                teachingLog.teacher_user.chinese_name
              )} - ${teachingLog.teacher_user.email}`
            }}
          </router-link>
        </el-form-item>
        <el-form-item label="Lesson Time">
          {{
            `${teachingLog.started_at} (Taipei time)(${
              teachingLog.full_hour
            } hour${teachingLog.full_hour === 1 ? "" : "s"})`
          }}
        </el-form-item>
        <el-form-item label="Today's Lesson">
          <div v-html="teachingLog.description" />
        </el-form-item>
        <el-form-item label="Homework">
          <div v-html="teachingLog.homework" />
        </el-form-item>
        <el-form-item label="Individual Notes"></el-form-item>
        <el-form-item
          label="Notes To Self"
          v-if="isRoleAdmin() || isRoleTeacher()"
        >
        </el-form-item>
        <el-form-item label="Next Lesson">
          {{
            teachingLog.next_lesson_at === "INVALID DATE"
              ? "Not scheduled"
              : `${teachingLog.next_lesson_at} (Taipei time)`
          }}
        </el-form-item>
        <el-form-item label="Salary">
          {{
            `${addCurrencySymbol(
              Number(teachingLog.salary),
              teachingLog.salary_currency
            )} x ${teachingLog.full_hour} hr = ${addCurrencySymbol(
              Number(teachingLog.salary) * teachingLog.full_hour,
              teachingLog.salary_currency
            )}`
          }}
          <div>
            <!-- {{ `(Total salary = ${addCurrencySymbol(totalSalary(), "TWD")})` }} -->
          </div>
        </el-form-item>
        <el-form-item label="Tuition">
          <div :key="student.id" v-for="student in lessonLogs">
            <span>
              {{
                `${user.displayName(
                  student.student_user.first_name,
                  student.student_user.last_name
                )}: `
              }}
            </span>
            {{
              `${addCurrencySymbol(
                Number(student.fee),
                student.fee_currency
              )} x ${teachingLog.full_hour} hr = ${addCurrencySymbol(
                Number(student.fee) * teachingLog.full_hour,
                student.fee_currency
              )}`
            }}
          </div>
          <!-- <div>
            {{
              `(Total tuition = ${addCurrencySymbol(totalTuition(), "TWD")})`
            }}
          </div> -->
        </el-form-item>
        <el-form-item label="Profit">
          <!-- {{
            `${addCurrencySymbol(totalTuition(), "TWD")} - ${addCurrencySymbol(
              totalSalary(),
              "TWD"
            )} = ${addCurrencySymbol(totalTuition() - totalSalary(), "TWD")}`
          }} -->
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import { user } from "@ivy-way/material";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import courseApi from "@/apis/course";
import privateApi from "@/apis/private";
import lessonLogApi from "@/apis/lessonLog";
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import AttendanceStatusLight from "@/components/privateLesson/AttendanceStatusLight";
import { EnumAttendanceStatuses } from "@/enums";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.lessonLog")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb
    // IsOnlineIcon,
    // AttendanceStatusLight
  },
  computed: {
    user() {
      return user;
    },
    EnumAttendanceStatuses() {
      return EnumAttendanceStatuses;
    },
    routePath() {
      if (this.isPrivateLesson) {
        return "private-lessons";
      } else if (this.isCounseling) {
        return "counseling";
      }
      return "session-class";
    },
    lessonTitle() {
      return this.sessionClass[this.isPrivateLesson ? "subject" : "title"];
    },
    logType() {
      return this.isCounseling ? "Meeting" : "Lesson";
    },
    isPrivateLesson() {
      return this.$route.params.privateType === "private-lessons";
    },
    isCounseling() {
      return this.$route.params.privateType === "counseling";
    },
    isTeachingLog() {
      let isTeachingLog = false;
      if (
        this.$route.query.isTeachingLog &&
        Number(this.$route.query.isTeachingLog) === 1
      ) {
        isTeachingLog = true;
      }
      return isTeachingLog;
    },
    isLessonLog() {
      return this.$route.name.indexOf("LessonLogDetail") !== -1;
    },
    currentStudent() {
      return this.lessonLogs.find(
        lessonLog => lessonLog.student_user_id === this.currentStudentId
      );
    },
    currentStudentTotalScore() {
      return this.currentStudent.total_score;
    },
    currentStudentScore() {
      return this.currentStudent.score;
    }
  },
  mixins: [moneyMixin, roleMixin],
  data() {
    return {
      students: [],
      lessonLogs: [],
      teachingLog: {},
      currentStudentId: null,
      sessionClass: null,
      nextTeachingLog: {},
      classInfo: null
    };
  },
  async created() {
    
    const { lesson_logs, teaching_log } = await this.fetchCourseLog();
    this.students = this.pickUpStudents(lesson_logs);
    // this.currentStudentId = this.students[0].student_user_id;
    this.lessonLogs = lesson_logs;
    this.teachingLog = teaching_log;
    this.teachingLog.started_at = moment(this.teachingLog.started_at)
      .format("YYYY-MM-DD HH:mm a")
      .toUpperCase();
    this.teachingLog.next_lesson_at = moment(this.teachingLog.started_at)
      .format("YYYY-MM-DD HH:mm a")
      .toUpperCase();
    const res = await courseApi.fetchSessionClass(
      this.teachingLog.class_id
    );
    this.classInfo = res.session_class;
    // await this.fetchNextTeachingLog();
  },
  methods: {
    filterStudentByAttendance(students, attendanceStatus) {
      return students.filter(
        ({ attendance_status }) => attendance_status === attendanceStatus
      );
    },
    async fetchCourseLog() {
      if (this.isTeachingLog) {
        return await lessonLogApi.fetchTeachingLog(
          this.$route.params.lessonLogId
        );
      } else if (this.isLessonLog) {
        return await lessonLogApi.fetchLessonLog(
          this.$route.params.lessonLogId
        );
      }
    },
    changeCurrentStudent(studentId) {
      this.currentStudentId = studentId;
    },
    pickUpStudents(lessonLogs) {
      if (this.isTeachingLog) {
        return lessonLogs;
      } else if (this.isLessonLog) {
        return lessonLogs.filter(
          lessonLog =>
            lessonLog.student_user_id === Number(this.$route.params.studentId)
        );
      }
    },
    totalSalary() {
      const theTwdSalary = this.convertPriceByCurrency(
        this.teachingLog.salary * this.teachingLog.full_hour,
        this.teachingLog.salary_currency,
        "TWD"
      );
      return theTwdSalary;
    },
    totalTuition() {
      const theTwdTuitions = this.lessonLogs.map(student =>
        this.convertPriceByCurrency(
          student.fee * this.teachingLog.full_hour,
          student.fee_currency,
          "TWD"
        )
      );
      return theTwdTuitions.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
    },
    getAttendanceStatusByUserId(userId) {
      return this.lessonLogs.find(
        ({ student_user_id }) => student_user_id === userId
      ).attendance_status;
    },
    getAbsenceExcuseByUserId(userId) {
      return this.lessonLogs.find(
        ({ student_user_id }) => student_user_id === userId
      ).absence_excuse;
    }
  }
};
</script>

<style lang="scss" scoped>
.lessonCard {
  padding: 20px;
  width: 970px;
  margin: 40px auto;
  border: 1px solid #444;
  .rowGap {
    margin: 10px 0px;
  }
  .alignRight {
    text-align: right;
  }
}

.attendanceStatusLight {
  margin-right: 8px;
}

.absenceExcuse {
  color: #6e90be;
}
</style>
