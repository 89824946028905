var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":`/private/private-lessons/${_vm.teachingLog.class_id}/detail`}},[_vm._v(" "+_vm._s(_vm.classInfo.title)+" ")])],1),_c('el-breadcrumb-item',[_vm._v("Lesson Log Details")])],1)],2),_c('h3',[_vm._v(_vm._s(_vm.classInfo.title)+" - Lesson Log")]),_c('hr',{staticClass:"separate-line"}),_vm._l((_vm.lessonLogs),function(lessonLog){return _c('el-card',{key:lessonLog.id,staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Details")]),_c('router-link',{attrs:{"to":`/private/private-lessons/${_vm.teachingLog.class_id}/editLog/${lessonLog.id}`}},[_c('el-button',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"type":"text"}},[_vm._v("Edit")])],1)],1),_c('el-form',{ref:"form",refInFor:true,attrs:{"label-width":"180px"}},[_c('el-form-item',{attrs:{"label":"Student"}},[_c('router-link',{attrs:{"to":{
            name: 'ProfileForAdmin',
            params: { id: lessonLog.student_user.id }
          }}},[_vm._v(" "+_vm._s(`${_vm.user.displayName( lessonLog.student_user.first_name, lessonLog.student_user.last_name, lessonLog.student_user.chinese_name )}`)+" ")])],1),_c('el-form-item',{attrs:{"label":"Teacher"}},[_c('router-link',{attrs:{"to":{
            name: 'ProfileForAdmin',
            params: { id: _vm.teachingLog.teacher_user.id }
          }}},[_vm._v(" "+_vm._s(`${_vm.user.displayName( _vm.teachingLog.teacher_user.first_name, _vm.teachingLog.teacher_user.last_name, _vm.teachingLog.teacher_user.chinese_name )} - ${_vm.teachingLog.teacher_user.email}`)+" ")])],1),_c('el-form-item',{attrs:{"label":"Lesson Time"}},[_vm._v(" "+_vm._s(`${_vm.teachingLog.started_at} (Taipei time)(${ _vm.teachingLog.full_hour } hour${_vm.teachingLog.full_hour === 1 ? "" : "s"})`)+" ")]),_c('el-form-item',{attrs:{"label":"Today's Lesson"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.teachingLog.description)}})]),_c('el-form-item',{attrs:{"label":"Homework"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.teachingLog.homework)}})]),_c('el-form-item',{attrs:{"label":"Individual Notes"}}),(_vm.isRoleAdmin() || _vm.isRoleTeacher())?_c('el-form-item',{attrs:{"label":"Notes To Self"}}):_vm._e(),_c('el-form-item',{attrs:{"label":"Next Lesson"}},[_vm._v(" "+_vm._s(_vm.teachingLog.next_lesson_at === "INVALID DATE" ? "Not scheduled" : `${_vm.teachingLog.next_lesson_at} (Taipei time)`)+" ")]),_c('el-form-item',{attrs:{"label":"Salary"}},[_vm._v(" "+_vm._s(`${_vm.addCurrencySymbol( Number(_vm.teachingLog.salary), _vm.teachingLog.salary_currency )} x ${_vm.teachingLog.full_hour} hr = ${_vm.addCurrencySymbol( Number(_vm.teachingLog.salary) * _vm.teachingLog.full_hour, _vm.teachingLog.salary_currency )}`)+" "),_c('div')]),_c('el-form-item',{attrs:{"label":"Tuition"}},_vm._l((_vm.lessonLogs),function(student){return _c('div',{key:student.id},[_c('span',[_vm._v(" "+_vm._s(`${_vm.user.displayName( student.student_user.first_name, student.student_user.last_name )}: `)+" ")]),_vm._v(" "+_vm._s(`${_vm.addCurrencySymbol( Number(student.fee), student.fee_currency )} x ${_vm.teachingLog.full_hour} hr = ${_vm.addCurrencySymbol( Number(student.fee) * _vm.teachingLog.full_hour, student.fee_currency )}`)+" ")])}),0),_c('el-form-item',{attrs:{"label":"Profit"}})],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }